import { makeVariant } from '../../../types/makeVariant'

export const links = makeVariant({
  primary: {
    variant: 'buttons.text',
    justifyContent: 'center',
  },
  plain: {
    color: 'inherit',
  },
  text: {
    color: 'primary',
    textDecoration: 'underline',
    '&:visited': {
      color: 'gray',
    },
  },
  navbar: {
    '--icon-size': 'var(--links-navbar-icon-size, 1em)',
    flex: ['1 1 auto', undefined, '0 0 auto'],
    flexDirection: ['column', 'row'],
    color: 'rgba(0,0,0,0.5)',
    backgroundColor: [undefined, undefined, 'background'],
    borderRight: ['1px solid var(--color-border)', undefined, '0'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 2,
    position: 'relative',
    transition: 'color 300ms',
    borderRadius: 2,
    '&:focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--color-focus)',
    },
    '&.active': {
      color: ['primary', undefined, 'text'],
    },
    '&:hover': {
      color: 'black',
    },
    '> svg': {
      width: 'var(--icon-size)',
      height: 'var(--icon-size)',
      fontSize: '1.5em',
      '&:not(:last-child)': {
        mr: [0, 2],
      },
    },
    '&:first-child': {
      pl: 0,
    },
    '&:last-child': {
      borderRight: '0',
    },
  },
  actionIconOnly: {
    variant: 'layout.center',
    backgroundColor: 'background',
    borderRadius: '3px',
    width: '24px',
    height: '24px',
    '& > svg': {
      fontSize: '1em',
    },
  },
  appNavbarButton: {
    '--_color': 'rgb(var(--rgb-text) / 0.5)',
    '--_color-active': 'var(--color-primary)',
    '--_color-hover': 'text',
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gap: 3,
    fontSize: 4,
    px: '18px',
    height: '46px',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'color 300ms',
    position: 'relative',
    whiteSpace: 'nowrap',
    borderRadius: 2,
    '&, &:visited': {
      color: 'var(--_color)',
    },
    '&:hover': {
      color: 'var(--_color-hover)',
    },
    '&[aria-current],&.force-active': {
      color: 'var(--_color-active)',
    },
    '> svg': {
      width: '24px',
      height: '24px',
      fontSize: '1.5em',
    },
    '&:focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--color-focus)',
    },
  },
  appNavbarButtonHelp: {
    variant: 'links.appNavbarButton',
    height: '64px',
    my: '8px',
    boxShadow: '0px 1px 1px #eee, 0px -1px 1px #eee',
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '8px',
      right: '8px',
      borderTop: '1px solid',
      borderTopColor: 'border',
      borderBottom: '1px solid',
      borderBottomColor: 'border',
    },
    '&:focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--color-focus)',
      '&::after': {
        border: 0,
      },
    },
  },
  action: {
    variant: 'buttons.action',
  },
  backHomePageLink: {
    '--backhomepagelinkhover': '#333',
    color: 'var(--backhomepagelink)',
    '&:visited': {
      color: 'var(--backhomepagelinkvisited, var(--backhomepagelink, #927ece))',
    },
    '&:hover': {
      color: 'var(--backhomepagelinkhover, #333)',
    },
    position: ['absolute', 'inherit', 'inherit'],
    left: ['24px', 0, 0],
    mt: ['160px', 0, 0],
    fontSize: 3,
    fontWeight: 'light',
    mr: '16px',
  },
  publicFooterLink: {
    cursor: 'pointer',
    color: '#6E6C8D',
    '&:hover': {
      color: '#333',
    },
  },
  topToolBar: {
    variant: 'links.appNavbarButton',
    px: 2,
    display: 'flex',
    fontSize: 3,
    alignSelf: 'center',
    transition: 'unset',
    '&[aria-current],&.force-active': {
      color: 'text',
    },
  },
})
