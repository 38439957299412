import { makeVariant } from '../../../types/makeVariant'

export const badges = makeVariant({
  primary: {
    minHeight: '24px',
    minWidth: '24px',
    borderRadius: '4px',
    padding: '4px',
    bg: 'primary',
    color: 'accentText',
    fontSize: 1,
  },
})
