import type { BrandConfig, BrandThemeIconMap } from '@jotta/types/Brand'
import type { Theme } from 'theme-ui'
import { icons } from './icons'
import { variants } from './variants'
import { dirname } from 'path'
import type { BrandTheme } from '../../types/BrandTheme'

export const exactBaseBrandTheme = {
  icons,
  branding: {
    brand: 'TEST',
    themedir: dirname(new URL(import.meta.url).pathname),
    appIconSvg: '',
    favIconSvg: '',
    metaTitle: 'Test',
    staticDir: 'test',
    width: 116,
    height: 25,
    learnMoreLinkWaitlist: 7262803,
    learnMoreLinkSearch: 7262816,
    sidebar: {
      closedWidth: ['0px', '0px', '59px', '59px'],
      openWidth: ['260px', '260px', '260px'],
      showPoweredByJotta: false,
    },
    header: {
      height: 60,
      showBrandLogo: true,
      showHamburger: true,
      showSignupButtons: true,
    },
    topToolBar: {
      height: 60,
    },
    bottomToolBar: {
      height: 50,
    },
    photos: {
      timeline: {
        headerRowAspect: [8, 12, 16, 16],
        imageRowAspect: [4, 6, 8, 10],
        scrubberWidth: [0, 80],
        mobileTimelineBreakpoint: 750,
      },
    },

    files: {
      folderColumnWidth: 130,
      folderRowHeight: 48,
      publicFolderRowHeight: 54,
      folderThumbAspect: 1.25,
    },
    settings: {
      showFamilyPicture: true,
      inviteFamilyMemberBySMSenabled: true,
      showLinkedAccounts: false,
      usesExternalIDP: false,
    },
    showLanguageSelector: true,
  } as BrandConfig,
  breakpoints: ['600px', '1024px', '1440px'],
  breakPointNames: ['mobile', 'tablet', 'desktop'],
  extendedBreakpoints: {
    'tablet-sm': '768px',
  },
  radii: [0, 2, 5, 10],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256],
  sizes: [0, 4, 8, 16, 24, 32, 64, 128, 256],
  fontSizes: [12, 13, 15, 16, 19, 24, 32, 48, 64],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Inter, Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    light: 300,
    body: 400,
    button: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    accent: '#59c150',
    accentText: '#333',
    activeGray: '#ccc',
    activeGrayText: '#333',
    background: '#fff',
    backgroundText: '#333',
    blackIshBg: '#2b2b2b',
    border: '#eaeaea',
    borderText: '#333',
    carouselBackground: '#000',
    danger: '#ED1C24',
    dangerText: '#fff',
    darkerGray: '#0e0e0e',
    darkGray: '#555',
    darkGrayText: '#fff',
    focus: '#9C61E8',
    focusDark: '#9C61E8',
    gray: '#888',
    grayHeading: '#4A4A4A',
    graySlateBgColor: 'rgb(69, 67, 76)',
    graySlateOpacityBg: 'rgba(69,67,76,.8)',
    grayText: '#fff',
    highlight: '#fc6',
    highlightText: '#333',
    indicatorPurple: '#9c61e8',
    indicatorDate: 'rgba(69, 67, 76, 0.8)',
    lightGray: '#f3f3f3',
    lightGrayBg: '#eee',
    lightGrayText: '#333',
    lightGrey: '#EAEAEA',
    message: '#499cf0',
    messageText: '#fff',
    modalBackground: 'rgba(0,0,0,0.5)',
    muted: '#f9f9f9',
    mutedText: '#333',
    photoInfoBgColor: '#2c2c2c',
    primary: '#9C61E8',
    primaryText: '#fff',
    purpleHueGray: '#45434c',
    scrubberMonthDot: '#ccc',
    scrubberYearDot: '#999',
    shareIcon: '#46cd85',
    secondary: '#fff',
    secondaryText: '#333',
    selection: '#1364A3',
    success: '#59c150',
    successText: '#333',
    text: '#333',
    textText: '#fff',
    warning: '#fc0',
    warningText: '#333',
    photoSearchGray: '#6D747B',
    photoSearchInactive: '#6D747B',
  },
  borderWidths: [0, 1, 2],
  ...variants,
} as const satisfies {
  icons: BrandThemeIconMap
  branding: BrandConfig
  breakPointNames: ['mobile', 'tablet', 'desktop']
  extendedBreakpoints: {
    'tablet-sm': string
  }
} & Theme

export const basetheme: BrandTheme = exactBaseBrandTheme
export default basetheme
