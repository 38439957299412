import { makeVariant } from '../../../types/makeVariant'

export const alerts = makeVariant({
  info: {
    width: '100%',
    display: 'block',
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'border',
    backgroundColor: 'background',
    color: 'backgroundText',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    '> .alert__content': {
      p: 2,
    },
    '> .alert__actions': {
      fontSize: 1,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'border',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'border',
      },
    },
  },
  success: {
    variant: 'alerts.info',
    borderColor: 'success',
    // backgroundColor: lighten('success', 0.1),
    // color: 'successText',
    '> .alert__actions': {
      fontSize: 1,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'rgb(var(--rgb-success-text) / 0.2)',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'rgb(var(--rgb-success-text) / 0.2)',
      },
    },
  },
  warning: {
    variant: 'alerts.info',
    borderColor: 'warning',
    // backgroundColor: lighten('warning', 0.1),
    // color: 'warningText',
    '> .alert__actions': {
      fontSize: 1,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'rgb(var(--rgb-warning-text) / 0.2)',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'rgb(var(--rgb-warning-text) / 0.2)',
      },
    },
  },
  error: {
    variant: 'alerts.info',
    borderColor: 'danger',
    // backgroundColor: lighten('danger', 0.1),
    // color: 'dangerText',
    '> .alert__actions': {
      fontSize: 1,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'rgb(var(--rgb-danger-text) / 0.2)',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'rgb(var(--rgb-danger-text) / 0.2)',
      },
    },
  },
  photoUploadComplete: {
    variant: 'alerts.info',
    backgroundColor: 'primary',
    color: 'primaryText',
    '> .alert__content': {
      '> :is(p, h3)': {
        color: 'currentColor',
      },
      p: 4,
    },
    '> .alert__actions': {
      fontSize: 1,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'border',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'border',
      },
    },
  },
  photoUploadError: {
    variant: 'alerts.error',
    '> .alert__content': {
      p: 4,
    },
    '> .alert__actions': {
      fontSize: 1,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'border',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'border',
      },
    },
  },
  followSharedAlbum: {
    variant: 'alerts.photoUploadComplete',
  },
  loginToAddPhotosAlert: {
    variant: 'alerts.info',
    bg: 'muted',
    borderRadius: '4px',
    boxShadow: '0 0 8px rgb(0 0 0 / 13%)',
    color: '#515151',
    fontSize: 2,
    '> .alert__content': {
      p: 3,
    },
    '> .alert__actions': {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: '#ddd',
      p: 2,
      ':hover': { bg: 'rgba(0,0,0,.02)' },
    },
    '.alert__button': {
      justifyContent: 'start',
    },
  },
  addPhotosToAlbumAlert: {
    variant: 'alerts.info',
    pointerEvents: 'auto',
    fontSize: 2,
    '> .alert__actions': {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'border',
      '> *:not(:last-child) ': {
        borderRight: '1px solid transparent',
        borderRightColor: 'border',
      },
    },
    '.alert__button': {
      justifyContent: 'center',
    },
  },
})
