import { makeVariant } from '../../../types/makeVariant'

export const forms = makeVariant({
  label: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    pb: 2,
    '&[aria-hidden="true"]': {
      pb: 0,
      visibility: 'hidden',
      height: 0,
      overflow: 'hidden',
      pointerEvents: 'none',
    },
  },
  labelHorizontal: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
  },
  input: {
    fontSize: 2,
    color: '#000',
    border: 'none',
    py: 1,
    px: 0,
    outline: 'none',
    borderRadius: 0,
    borderBottom: '1px solid',
    borderBottomColor: '#ddd',
    transition: 'border-bottom-color .1s',
    '&::placeholder': {
      opacity: 0.6,
    },
    '&:invalid': {
      borderBottomColor: 'danger',
    },
    '&:focus': {
      borderBottomColor: 'primary',
    },
    '&:focus-visible': {
      boxShadow: 'none',
      '&::placeholder': {
        opacity: 0.5,
      },
    },
    '&:disabled': {
      color: 'gray',
    },
  },
  phoneInput: {
    position: 'relative',
    '> .icon': {
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',

      '&.verified-icon': {
        zIndex: 2,
      },
      '&.reset-icon': {
        cursor: 'pointer',
        zIndex: 3,
        opacity: 0,
      },
    },
    '&.is-focused .icon, &:hover .icon': {
      transition: 'opacity 300ms',
      '&.reset-icon': {
        opacity: 0.5,
      },
      '&.verified-icon': {
        opacity: 0,
      },
    },
    '.react-tel-input': {
      zIndex: 1,
      'input[type="tel"]': {
        variant: 'forms.input',
        pl: '48px',
        height: 'auto',
        width: '100%',
      },
      '.flag-dropdown, .flag-dropdown.open': {
        all: 'unset',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        '.selected-flag': {
          '&, &.open, &:focus, &:hover': {
            background: 'transparent',
          },
        },
      },
      '.country-list': {
        maxHeight: '50vh',
      },
    },
  },
  checkbox: {
    cursor: 'pointer',
    border: '2px solid',
    backgroundColor: 'background',
    borderColor: 'gray',
    borderRadius: 4,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    width: 'min-content',
    height: 'min-content',
    fontSize: '16px',
    '&:not(:last-child)': {
      mr: 2,
    },
    '& > svg': {
      fontSize: '1em',
      opacity: 0,
      transition: 'opacity 100ms',
    },
    'input:checked ~ &': {
      borderColor: 'primary',
      backgroundColor: 'primary',
      color: 'background',
      '& > svg': {
        opacity: 1,
      },
    },
    'input[aria-invalid="true"] ~ &': {
      borderColor: 'danger',
    },
    'input[aria-invalid="true"]:checked ~ &': {
      backgroundColor: 'danger',
    },
    // 'input:focus ~ &': {
    //   color: 'primary',
    //   bg: 'highlight',
    // },
  },
  fieldControlInvalid: {
    borderBottomColor: 'danger',
    '&:focus': {
      borderBottomColor: 'danger',
    },
  },
  fieldControlMessage: {
    fontFamily: 'body',
    fontWeight: 'body',
    fontSize: 1,
    lineHeight: 'body',
    color: 'danger',
    height: '18px',
  },
  select: {
    variant: 'forms.input',
  },
  textarea: {
    variant: 'forms.input',
  },
  slider: {
    bg: 'muted',
  },
  buttonGroup: {
    display: 'grid',
    gap: [2, 4],
    gridTemplateColumns: [
      '1fr',
      'repeat(auto-fit, minmax(150px, max-content))',
    ],
    alignItems: 'start',
    justifyContent: 'start',
  },
  buttonGroupRight: {
    variant: 'forms.buttonGroup',
    justifyContent: 'end',
  },
  buttonGroupReversed: {
    display: 'flex',
    gap: [2, 4],
    flexFlow: 'row-reverse nowrap',
    justifyContent: 'end',
  },
  toggle: {
    display: 'inline-block',
    outline: 'none',
    marginBottom: '0',
    verticalAlign: 'middle',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    textIndent: '-12rem',
    flex: '0 0 auto',
    border: 'none',
    '&::before': {
      content: "' '",
      display: 'block',
      height: '75%',
      width: '100%',
      borderRadius: '1em',
      backgroundColor: 'border',
      opacity: 1,
    },
    '&:checked::before': {
      backgroundColor: 'primary',
      opacity: 0.5,
    },
    '&::after': {
      content: "' '",
      position: 'absolute',
      borderRadius: '50%',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
      backgroundColor: 'border',
      transition: 'transform 100ms',
    },
    '&:checked::after': {
      backgroundColor: 'primary',
    },
  },
})
