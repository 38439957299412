import { makeVariant } from '../../../types/makeVariant'

export const text = makeVariant({
  primary: {
    fontSize: 1,
  },
  sharePageTitle: {
    fontSize: 'clamp(18px, 32px, 3vmin)',
    lineHeight: '1.5',
    textTransform: 'uppercase',
    fontWeight: 'light',
    margin: 0,
    color: 'text',
  },
  sharePageSubTitle: {
    fontSize: 'clamp(12px, 16px, 1.5vmin)',
    lineHeight: 1.4,
    fontWeight: 'body',
    opacity: 0.6,
    padding: '15px 0',
    color: 'text',
  },
  mediaCarouselHeader: {
    fileSize: {
      fontSize: 1,
      opacity: 0.6,
      flexShrink: 0,
    },
    subTitle: {
      fontSize: 2,
      opacity: 0.6,
    },
    title: {
      fontSize: 4,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
})
