import { makeVariant } from '../../../types/makeVariant'

export const formats = makeVariant({
  primary: {
    h1: {
      variant: 'styles.h1',
    },
    h2: {
      variant: 'styles.h2',
    },
    h3: {
      variant: 'styles.h3',
    },
    h4: {
      variant: 'styles.h4',
    },
    h5: {
      variant: 'styles.h5',
    },
    h6: {
      variant: 'styles.h6',
    },
    p: {
      variant: 'styles.p',
    },
    a: {
      variant: 'styles.a',
    },
    pre: {
      variant: 'styles.pre',
    },
    ul: {
      variant: 'styles.ul',
    },
    ol: {
      variant: 'styles.ol',
    },
  },
  errorPage: {
    variant: 'formats.primary',
    color: '#5a5a5a',

    h2: {
      fontWeight: 'light',
      fontStyle: 'italic',
      fontSize: 6,
      mt: 0,
      mb: 3,
      color: '#364b5a',
    },
    p: {
      variant: 'styles.p',
      fontSize: 3,
      fontWeight: 'light',
      mb: 3,
    },
    a: {
      variant: 'styles.a',
      fontWeight: 'light',
    },
  },
  onboarding: {
    variant: 'formats.primary',
    h3: {
      variant: 'styles.h3',
      mb: 3,
    },
    p: {
      variant: 'styles.p',
      fontWeight: 'light',
    },
  },
  welcome: {
    variant: 'formats.primary',
    h3: {
      // variant: 'styles.h3',
      fontSize: 7,
      fontWeight: 'body',
      lineHeight: 1.2,
      m: 0,
    },
    h2: {
      // variant: 'styles.h2',
      fontWeight: 'light',
      fontSize: 6,
      lineHeight: 1.2,
      m: 0,
    },
    p: {
      variant: 'styles.p',
      my: 2,
      fontSize: 3,
      fontWeight: 'light',
    },
  },
  photoState: {
    variant: 'formats.primary',
    textAlign: 'center',
    h2: {
      fontWeight: 'heading',
      fontSize: 5,
      lineHeight: 1.2,
    },
    p: {
      variant: 'styles.p',
      my: 2,
      fontSize: 3,
      fontWeight: 'light',
    },
  },
})
