import { makeVariant } from '../../../types/makeVariant'

export const tables = makeVariant({
  responsiveTable: {
    width: '100%',
    fontSize: 2,

    thead: {
      color: 'darkGray',
    },

    td: {
      verticalAlign: 'top',
      '&[data-scope="action"]': {
        textAlign: 'right',
      },
      '&:nth-child(1)': {
        width: '30%',
      },
      '&:nth-child(2)': {
        width: '30%',
      },
      '&:nth-child(3)': {
        width: '15%',
      },
      '&:nth-child(4)': {
        width: '15%',
      },
      '&:nth-child(5)': {
        width: '10%',
      },
    },
  },
})
