import { makeVariant } from '../../../types/makeVariant'
import { getBrandZIndex } from '../../zIndex'

export const layout = makeVariant({
  container: {
    flex: '1 1 auto',
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  containerDownload: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  containerVertical: {
    variant: 'layout.container',
    flexDirection: 'column',
  },
  absolute: {
    variant: 'layout.container',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  scroll: {
    variant: 'layout.container',
    overflowY: 'auto',
  },
  base: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative',
  },
  baseCol: {
    variant: 'layout.base',
    flexDirection: 'column',
  },
  flexCenter: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
  },
  appLayout: {
    variant: 'layout.container',
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    top: '0',
    overflow: 'hidden',
    zIndex: 2,
  },
  appLayoutContent: {
    position: 'absolute',
    left: 'var(--content-left, 0px)',
    right: '0',
    top: 'var(--content-top, 0px)',
    zIndex: 1,
  },
  appHeader: {
    width: '100vw',
    paddingRight: 'var(--scrollbar-width, 0px)',
    backgroundColor: 'background',
    borderBottom: '1px solid',
    borderBottomColor: 'border',
    opacity: 'var(--fade-when-fab-is-open, 1)',
    transition: 'opacity 300ms',
    zIndex: getBrandZIndex('appHeader'),
  },
  appHeaderContent: {
    flex: '1 1 auto',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr max-content max-content',
    gap: 0,
    pl: 2,
    alignItems: 'center',
    marginRight: [0, '12px'],
    height: '100%',
    width: '100%',
    '.headerSlot': {
      display: 'grid',
      width: '100%',
      maxWidth: '100%',
    },
  },
  appHeaderDownload: {
    height: 80,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    borderBottom: ['1px solid', 'none', 'none'],
    borderBottomColor: ['border', 'transparent', 'transparent'],
    justifyContent: 'space-between',
    px: [4, 4, 7],
    zIndex: getBrandZIndex('languageSelector'),
  },
  downloadSection: {
    backgroundColor: '#fbfbfb',
    textAlign: 'center',
    py: '100px',
    px: '2',
  },
  appFooterPublicContainer: {
    alignItems: 'center',
    backgroundColor: '#f2f4f6',
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '50px 0',
    width: '100%',
    fontSize: '14px',
    lineHeight: 1.4,
    py: 6,
    px: [4, 4, 7],
    zIndex: getBrandZIndex('appMainBottomToolbar'),
    '& > *:not(:last-child)': {
      mb: '24px',
    },
  },
  jottaAppFooterContainer: {
    borderTop: '1px solid #eaeaea',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    color: 'black',
    padding: '56px',
  },
  publicFooterWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: ['column nowrap', 'row nowrap', 'row nowrap'],
    width: '100%',
    gap: 3,
  },
  publicFooter: {
    backgroundColor: 'footerBackground',
    fontSize: '14px',
    lineHeight: '1.4',
    py: '6',
    px: [4, 4, 7],
  },
  appHeaderHamburger: {
    minWidth: '50px',
    pl: '18px',
    pr: '5px',
    borderRadius: 2,
    '&:focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--color-focus)',
    },
  },
  center: {
    variant: 'layout.container',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerInline: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerAbsolute: {
    variant: 'layout.center',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  centerColumn: {
    variant: 'layout.center',
    flexFlow: 'column nowrap',
  },
  appNavbar: {
    contain: 'content',
    position: 'fixed',
    top: 'var(--header-height, 60px)',
    left: 0,
    bottom: 0,
    paddingTop: '8px',
    width: 'var(--sidebar-width, 0px)',
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: 'muted',
    borderRight: '1px solid',
    borderRightColor: 'border',
    overflow: 'hidden',
    zIndex: getBrandZIndex('appNavbar'),
    transition: 'opacity 300ms, width 300ms',
    opacity: 'var(--fade-when-fab-is-open, 1)',
    '&[data-open="true"]': {
      zIndex: [
        getBrandZIndex('appNavbarOpenMobile'),
        null,
        getBrandZIndex('appNavbar'),
      ],
    },
  },
  appNavbarBg: {
    backgroundColor: 'modalBackground',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: [
      getBrandZIndex('appNavbarOpenMobileBg'),
      null,
      getBrandZIndex('appNavbarBg'),
    ],
  },

  appTopToolbar: {
    display: 'flex',
    flex: '1 1 auto',
    zIndex: getBrandZIndex('appMainTopToolbar'),
    backgroundColor: 'background',
    whiteSpace: 'nowrap',
    fontSize: 2,
    justifyContent: 'flex-start',
    mx: 3,
    px: 2,
    boxShadow: '0 4px 5px -5px rgb(0 0 0 / 30%)',
    borderBottom: '1px solid var(--color-border)',
    transition: 'opacity 300ms, width 300ms',
    opacity: 'var(--fade-when-fab-is-open, 1)',
    '&:empty': {
      display: 'none',
    },
  },
  appBottomToolbar: {
    backgroundColor: 'muted',
    zIndex: 1,
    whiteSpace: 'nowrap',
    fontSize: [1, 2],
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0 4px 5px -5px rgb(0 0 0 / 30%)',
    borderTop: '1px solid var(--color-border)',
    opacity: 'var(--fade-when-fab-is-open, 1)',
    pr: 'var(--removed-body-scroll-bar-size, 0px)',
    '&:empty': {
      display: 'none',
    },
  },
  appAlerts: {
    position: 'fixed',
    bottom: 'calc(var(--content-bottom) + var(--s3))',
    left: 3,
    right: [3, '50%', '25%'],
    maxWidth: '400px',
    zIndex: getBrandZIndex('alertList'),
  },
  downloadSectionContainer1: {
    backgroundColor: 'background',
    textAlign: 'center',
    py: '100px',
    px: '2',
  },
  downloadSectionContainer2: {
    display: 'flex',
    justifyContent: 'center',
  },
  downloadPageContainer: {
    backgroundColor: 'white',
    height: '100%',
    flexGrow: 1,
  },
  downloadPageWrapper: {
    textAlign: 'center',
    mt: ['108px', '80px', '80px'],
    mb: '74px',
    px: 2,
  },
  modalBackground: {
    variant: 'layout.absolute',
    backgroundColor: 'modalBackground',
    backdropFilter: ['none', 'blur(10px)'],
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: getBrandZIndex('modal'),
    overflow: 'hidden',
  },
  modalBackgroundLight: {
    variant: 'layout.modalBackground',
    backgroundColor: 'rgb(var(--rgb-background) / 0.9)',
  },
  modalBackgroundLightFixed: {
    variant: 'layout.modalBackgroundLight',
    position: 'fixed',
  },
  modalBackgroundPrimary: {
    variant: 'layout.modalBackground',
    position: 'fixed',
    backgroundColor: 'rgb(var(--rgb-primary) / 0.9)',
  },
  gridRow: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gap: 0,
  },
  gridColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 0,
  },
  flexRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  flexColumn: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  flexColumnSpaced: {
    variant: 'styles.flexColumn',
    alignItems: 'space-between',
  },
  fileMenuContainer: {
    alignItems: 'start',
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row nowrap',
    width: '100%',

    '& > *:not(:last-child)': {
      margin: '0 24px 0 0',
    },

    '@media screen and (max-width: 425px)': {
      flexFlow: 'column nowrap',

      '& > *:not(:last-child)': {
        margin: '0 0 24px 0',
      },
    },
  },
  fileMenuWrapper: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *:not(:last-child)': {
      margin: '0 24px 0 0',
    },

    '@media screen and (max-width: 425px)': {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      width: '100%',

      '& > *:not(:last-child)': {
        margin: '0',
      },
    },
  },
  fileView: {
    display: 'grid',
    flex: '1 1 auto',
    px: 2,
    pb: 2,
    gridTemplateAreas: `
      "left center right"
      "content content content"
    `,
    gridTemplateColumns: '1fr min-content 1fr',
    gridTemplateRows: '60px 10fr',
  },
  fileViewLeft: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    gridArea: 'left',
  },
  fileViewCenter: {
    display: 'flex',
    gridArea: 'center',
  },
  fileViewRight: {
    display: 'flex',
    gridArea: 'right',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fileViewContent: {
    display: 'flex',
    gridArea: 'content',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  thumbnailListContainer: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: ['column', 'row', 'row', 'row'],
    flexWrap: 'wrap',
    margin: ['0, 0, 0 20px'],
    padding: '14px 9px',
    pt: '32px',
  },
  singleThumbnail: {
    cursor: 'pointer',
    width: '112px',
    height: '112px',
    position: 'relative',
    background: '#F8F9FA',
    display: 'inline-block',
    borderRadius: '2px',
    mb: '8px',
    '&:hover': {
      backgroundColor: '#FBFBF8',
    },
  },
  singleThumbnailHoverWrapper: {
    width: '130px',
    height: '200px',
    m: '2px',
    padding: '9px',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
  },
  settingsContainer: {
    gap: 4,
    py: 5,
    px: [2, '24px', '24px'],
  },
  tableContainer: {
    display: 'grid',
    gap: 2,
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 2fr 1fr)',
    },
    '@media screen and (min-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 2fr 1fr)',
    },
    '@media screen and (min-width: 1024px)': {
      gridTemplateColumns: 'repeat(1, 2fr 1fr 1fr 3fr)',
    },
    py: ['12px', '20px', '20px'],
  },
  tableHeadingsContainer: {
    display: 'grid',
    gap: 2,
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 2fr 1fr)',
    },
    '@media screen and (min-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 2fr 1fr)',
    },
    '@media screen and (min-width: 1024px)': {
      gridTemplateColumns: 'repeat(1, 2fr 1fr 1fr 3fr)',
    },
    pb: 0,
    pt: ['12px', '20px', '24px'],
  },
  mediaItemBackground: {
    content: '""',
    position: 'absolute',
    inset: 0,
    pointerEvents: 'none',
    background:
      'linear-gradient(black -100px, transparent 70px, transparent calc(100% - 70px), transparent calc(100% + 100px))',
  },
  smartPhotoBannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    width: '100%',
    maxWidth: '550px',
    borderRadius: '12px',
    padding: [3, 4],
    bg: 'white',
    border: '1px solid',
    borderColor: 'primary',
    mt: 3,
  },
})
