import { makeVariant } from '../../../types/makeVariant'
import { getBrandZIndex } from '../../zIndex'

export const styles = makeVariant({
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
  },
  h1: {
    // color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    marginBlockStart: '1em',
    marginBlockEnd: '0.25em',
    fontSize: 5,
    '> small': {
      display: 'inline-block',
      opacity: 0.5,
      marginLeft: '1em',
    },
    '&:first-child': {
      marginBlockStart: 0,
    },
  },
  h2: {
    variant: 'styles.h1',
    fontSize: 4,
  },
  h3: {
    variant: 'styles.h1',
    fontSize: 3,
  },
  h4: {
    variant: 'styles.h1',
    fontSize: 2,
  },
  h5: {
    variant: 'styles.h1',
    fontSize: 1,
  },
  h6: {
    variant: 'styles.h1',
    textTransform: 'uppercase',
    color: 'gray',
    fontSize: 0,
  },
  p: {
    // color: 'text',
    fontFamily: 'body',
    fontWeight: 'body',
    fontSize: 2,
    lineHeight: 'body',
    marginBlockStart: 0,
    marginBlockEnd: '0.25em',
    verticalAlign: 'middle',
    '&:last-child': {
      marginBlockEnd: 0,
    },
  },
  boldParagraphStyle: {
    variant: 'styles.p',
    fontWeight: 'bold',
  },
  ul: {
    listStyleType: 'disc',
  },
  ol: {
    listStyleType: 'decimal',
  },
  b: {
    fontWeight: 'bold',
  },
  strong: {
    fontWeight: 'bold',
  },
  a: {
    color: 'text',
    textDecoration: 'none',
    '&[href]': {
      color: 'primary',
    },
  },
  pre: {
    fontFamily: 'monospace',
    overflowX: 'auto',
    whiteSpace: 'pre-line',
    code: {
      color: 'inherit',
    },
  },
  code: {
    fontFamily: 'monospace',
    fontSize: 'inherit',
    whiteSpace: 'pre',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  th: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  td: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  img: {
    maxWidth: '100%',
  },
  darkSpinner: {
    color: 'background',
    opacity: 1,
    transition: 'opacity 300ms',
  },
  fileViewButton: {
    p: 0,
    fontSize: 2,
    color: 'text',
    bg: 'background',
    borderColor: 'transparent',
    outline: 'none',
  },
  hr: {
    my: 0,
    opacity: 0.1,
    color: 'currentColor',
  },
  dl: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gap: 1,
    alignSelf: 'start',
    dt: {
      fontWeight: 'bold',
    },
    dd: {
      fontWeight: 'body',
    },
  },
  debugDefinitionList: {
    variant: 'cards.debug',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    p: 2,
    fontSize: 1,
    gap: 1,
    width: '24em',
    alignSelf: 'start',
  },
  avatar: {
    position: 'relative',
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '0px',
    borderRadius: '50%',
    width: '1em',
    height: '1em',
    lineHeight: '1em',
    fontWeight: 'heading',
    userSelect: 'none',
    color: 'background',
    opacity: 1,
    p: [0, 0, 0, 0],
    '> span': {
      fontSize: '0.36em',
    },
  },
  buttonIcon: {
    width: '1em',
    height: '1em',
    fontSize: '1.5em',
    '&[data-is-spinner]': {
      left: '50%',
      opacity: 0.7,
      position: 'absolute',
    },
  },
  fixedSizeButtonIcon: {
    variant: 'styles.buttonIcon',
    width: '24px',
    height: '24px',
  },
  fileIcon: {
    '--file-icon-border-color': '#b3b3b3',
    '--file-icon-symbol-color': '#cacaca',
    '--file-icon-fill': 'white',
    '--folder-icon-border-color': 'var(--file-icon-border-color)',
    '--folder-icon-fill': 'white',
    '[data-svg^="folder"]': {
      vectorEffect: 'non-scaling-stroke',
      strokeWidth: 1,
      stroke: 'var(--folder-icon-border-color, gray)',
      strokeOpacity: 0.7,
      fill: 'var(--folder-icon-fill, white)',
      // '@media (-webkit-max-device-pixel-ratio: 1)': {
      //   shapeRendering: 'crispEdges',
      // },
    },
    '[data-svg^="file"]': {
      vectorEffect: 'non-scaling-stroke',
      strokeWidth: 1,
      stroke: 'var(--file-icon-border-color, gray)',
      strokeOpacity: 0.7,
      fill: 'var(--file-icon-fill, white)',
      // '@media (-webkit-max-device-pixel-ratio: 1)': {
      //   shapeRendering: 'crispEdges',
      // },
    },
    '[data-svg="file-symbol-html"]': {
      strokeWidth: 0,
      stroke: 'transparent',
      fill: 'var(--file-icon-border-color, gray)',
    },
    '[data-svg="folder-symbol"]': {
      fill: 'var(--folder-icon-border-color, gray)',
    },
  },
  fileinfo: {},
  fileItemTypeIcon: {
    position: 'relative',
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  fileItemTypeIconThumb: {
    display: 'block',
    flex: '1 1 auto',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  shareIcon: {
    color: '#46cd85',
    padding: 1,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: '3px solid currentColor',
    borderColor: 'currentColor transparent currentColor transparent',
    transform: 'rotate(0deg)',
    animation: 'loading-spinner 2s linear infinite',
    opacity: 0.4,

    '@keyframes loading-spinner': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
  spinnerOverlayBackground: {
    variant: 'layout.absolute',
    backgroundColor: 'rgb(var(--rgb-background) / 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: getBrandZIndex('loadingSpinner'),
  },
  spinnerOverlayModalBackground: {
    variant: 'styles.spinnerOverlayBackground',
    backgroundColor: 'rgb(var(--rgb-carousel-background) / 0.9)',
    color: 'background',
  },
  dataTable: {
    position: 'relative',
    width: '100%',
  },
  dataTableHeader: {
    top: 0,
    position: 'sticky',
    left: 0,
    width: '100%',
    backgroundColor: 'background',
    zIndex: getBrandZIndex('appMainDataTableHeader'),
    borderBottomWidth: [2],
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgb(var(--rgb-text) / 0.1)',
  },
  dataTableHeaderFileListMode: {
    top: 0,
    position: 'sticky',
    left: 0,
    width: '100%',
    backgroundColor: 'background',
    zIndex: getBrandZIndex('appMainDataTableHeader'),
    borderBottomWidth: [2],
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgb(var(--rgb-text) / 0.1)',
  },
  dataTableBodyRow: {
    alignItems: 'center',
    borderBottomWidth: [1],
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgb(var(--rgb-text) / 0.1)',
    overflow: 'hidden',
    fontSize: [1],
    zIndex: getBrandZIndex('appMainDataTableBody'),
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#fbfbfb',
    },
  },
  dataTableHeadRow: {
    alignItems: 'center',
    borderBottomWidth: [1],
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgb(var(--rgb-text) / 0.1)',
    overflow: 'hidden',
    fontSize: [1],
    zIndex: getBrandZIndex('appMainDataTableBody'),
    flex: '1 1 auto',
  },
  dataTableHeaderRow: {
    variant: 'styles.dataTableBodyRow',
    borderBottomWidth: [1],
    flex: '1 1 auto',
    fontWeight: 'bold',
  },
  dataTableBodyRowCell: {
    color: '#58585B',
    fontVariantNumeric: 'tabular-nums',
    whiteSpace: 'nowrap',
    flexFlow: 'column nowrap',
    textAlign: 'left',
    fontSize: 1,
    py: 2,
    px: 4,
    padding: '0 3px',
  },
  dataTableHeaderRowCell: {
    variant: 'styles.dataTableBodyRowCell',
    fontWeight: 'body',
    color: '#8F98A1',
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
  // Data table as table element
  dataTableTable: {
    variant: 'styles.dataTable',
    display: 'table',
    '> thead': {
      variant: 'styles.dataTableHeader',
      display: 'table-header-group',
      '> tr': {
        variant: 'styles.dataTableHeaderRow',
        display: 'table-row',
        '> th': {
          variant: 'styles.dataTableHeaderRowCell',
          display: 'table-cell',
        },
        '> td': {
          variant: 'styles.dataTableHeaderRowCell',
          display: 'table-cell',
        },
      },
    },
    '> tbody': {
      display: 'table-body-group',
      '> tr': {
        variant: 'styles.dataTableBodyRow',
        display: 'table-row',
        '> th': {
          variant: 'styles.dataTableBodyRowCell',
          display: 'table-cell',
        },
        '> td': {
          variant: 'styles.dataTableBodyRowCell',
          display: 'table-cell',
        },
      },
    },
  },
  progress: {
    color: 'primary',
    backgroundColor: 'border',
  },
  progressBar: {
    backgroundColor: 'primary',
  },
  uploadProgress: {
    backgroundColor: 'primary',
  },
  storageProgressBar: {
    backgroundColor: 'primary',
  },
  albumTitle: {
    variant: 'styles.h4',
    marginBlockStart: '0.5em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
  },
  albumCountText: {
    variant: 'styles.p',
    marginBlockEnd: '1em',
    '&:last-child': {
      marginBlockEnd: '1em',
    },
  },
  photoInfoStyles: {
    backgroundColor: 'photoInfoBgColor',
    color: 'white',
    width: '100%',
    gap: 0,
    p: 4,
    pt: 6,
    height: '100%',
    position: 'relative',
    gridAutoRows: 'min-content',
    'h1,h2,h3,h4,h5,p': {
      m: 0,
    },
    '.faded': {
      opacity: 0.8,
    },
  },
  familySettingHeading: {
    variant: 'styles.h1',
  },
  span: {
    color: 'purpleHueGray',
    whiteSpace: 'pre',
  },
  tr: {
    color: 'darkGray',
  },
  //Used on download page
  headerText: {
    fontSize: [5, 6, 6],
    fontWeight: 'light',
    margin: 0,
    lineHeight: 1,
    color: 'black',
  },
  subHeaderText: {
    fontSize: [4, 5, 5],
    fontWeight: 'light',
    lineHeight: '1.4',
    margin: 0,
    mt: '7px',
    mb: '50px',
    opacity: 0.5,
    color: 'black',
    textAlign: 'center',
  },
  headerAndTextWrapper: {
    mb: '26px',
  },
  downloadPageHeader: {
    fontSize: ['54px', '72px', '72px'],
    fontWeight: 'light',
    lineHeight: 1.1,
    letterSpacing: '-1px',
    color: '#9c61e8',
  },
  downloadSvg: {
    color: '#5f52bd',
  },
  publicFooterText: {
    color: '#a7a7a7',
    fontSize: '14px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  uploadIcon: {
    color: 'secondary',
    width: '270px',
    height: '182px',
    display: 'block',
    margin: 'auto',
    filter: 'contrast(0.4) grayscale(1) brightness(1.4)',
  },
  uploadHeading: {
    color: 'white',
    textAlign: 'center',
    fontSize: 5,
    fontWeight: 'body',
  },
  newAlbumP: {
    variant: 'styles.p',
    fontWeight: 'bold',
    mt: 3,
  },
  addMoreSvg: {
    color: 'primary',
  },
  albumInfo: {
    color: 'graySlateOpacityBg',
    fontSize: 2,
    mt: 2,
  },
  timelineHeading: {
    alignSelf: 'flex-end',
    marginBlockEnd: 0,
    marginBlockStart: 0,
    fontSize: 5,
    color: 'graySlateBgColor',
    fontWeight: 'heading',
  },
  defaultProfilePicSvg: {
    color: 'primary',
    height: '24px',
    width: '24px',
    position: 'absolute',
  },
  smartPhotoSearchParagraph: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#2D2F32',
    mb: ['12px', 4],
    mt: '12px',
  },
  smartPhotoSearchHeading: {
    fontWeight: 'heading',
    fontSize: [5, '28px'],
    lineHeight: '32px',
    letterSpacing: '-0.02em',
    color: '#2D2F32',
    mx: ['2px', 0],
  },
})
