import { makeVariant } from '../../../types/makeVariant'
import { getBrandZIndex } from '../../zIndex'

export const dialogs = makeVariant({
  content: {
    variant: 'cards.info',
    alignSelf: ['stretch', 'flex-start'],
    justifySelf: ['stretch', 'center'],
    px: 0,
    py: 4,
    mt: [0, 'min(10vh, 100px)'],
    mx: [0, 'auto'],
    backgroundColor: 'background',
    position: 'relative',
    width: '100%',
    height: ['100%', 'auto'],
    maxWidth: ['100%', '600px'],
    minHeight: ['100%', '250px'],
    maxHeight: ['100%', '85vh'],
    display: 'flex',
    flexFlow: 'column nowrap',
    // Create a stacking context for overlays
    transform: 'translateZ(0)',
    '> hgroup:first-child': {
      px: 4,
    },
  },
  header: {
    background: 'var(--dialog-bg, white)',
    padding: '24px 24px 10px',
    borderRadius: '5px 5px 0 0',

    '> h1': {
      color: '#4a4a4a',
      fontSize: 'var(--fz4)',
      fontWeight: 'body',
      margin: 0,
      padding: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '> h2': {
      fontWeight: 'body',
      fontStyle: 'normal',
      textTransform: 'uppercase',
      fontSize: 'var(--fz2)',
      color: 'rgba(54, 75, 90, 0.5)',
      margin: 0,
      padding: '0 0 10px 0',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '&.is-centered > :where(h1, h2)': {
      textAlign: 'center',
    },
  },
  overlay: {
    '--dialog-width': 'clamp(300px, 100%, min(600px, 100%))',
    backgroundColor: 'modalBackground',
    backdropFilter: 'blur(10px)',
    position: 'fixed',
    display: 'flex',
    alignItems: ['center', 'flex-start'],
    justifyContent: 'center',
    height: '100%',
    pt: [0, 'min(10vh, 100px)'],
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: getBrandZIndex('modal'),
    '> :first-of-type': {
      height: 'auto',
      width: 'var(--dialog-width)',
      maxWidth: 'var(--dialog-width)',
    },
    '&[aria-hidden="true"]': {
      zIndex: 9,
    },
  },
  overlayWide: {
    variant: 'dialogs.overlay',
    '--dialog-width': 'clamp(300px, 100%, min(1000px, 100%))',
  },
  overlayFileViewer: {
    '--dialog-width': '100%',
    backgroundColor: 'modalBackground',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: getBrandZIndex('modal'),
    overflow: 'hidden',
    '> :first-of-type': {
      height: '100%',
      width: 'var(--dialog-width)',
      maxWidth: 'var(--dialog-width)',
    },
    '&[aria-hidden="true"]': {
      zIndex: 9,
    },
  },
  overlayFileViewerSearch: {
    variant: 'dialogs.overlayFileViewer',
    zIndex: '1001',
  },
  overlayCommentsDrawer: {
    '--dialog-width': '440px',
    position: 'fixed',
    display: 'flex',
    height: '100%',
    width: 'min(440px, 100%)',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: getBrandZIndex('modal'),
    '> :first-of-type': {
      height: '100%',
      width: '100%',
      borderLeft: '1px solid rgb(234, 234, 234)',
      boxShadow: 'rgb(0 0 0 / 30%) 0px -3px 30px',
    },
    '&[aria-hidden="true"]': {
      zIndex: 9,
    },
  },
  overlayUploadDetails: {
    '--dialog-width': 'calc(100% - var(--s5))',
    backgroundColor: 'transparent',
    position: 'fixed',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 'var(--bottom-toolbar-open-height)',
    py: 4,
    zIndex: getBrandZIndex('modal'),
    '> :first-of-type': {
      variant: 'cards.primary',
      backgroundColor: 'background',
      position: 'relative',
      zIndex: 1,
      maxHeight: '100%',
      overflowY: 'auto',
      width: 'var(--dialog-width)',
      maxWidth: 'var(--dialog-width)',
    },
    '&[aria-hidden="true"]': {
      zIndex: 9,
    },
  },
  wrapper: {
    '--dialog-margin-top': ['0px', 'min(10dvh, 100px)'],
    '--dialog-max-height': [
      '100dvh',
      'calc(100dvh - var(--dialog-margin-top) - var(--bottom-toolbar-open-height))',
    ],
    '--dialog-bg': 'var(--color-background, white)',
    borderRadius: 1,
    flex: '1 1 auto',
    background: 'var(--dialog-bg, white)',
    color: '#333',
    textAlign: 'left',
    display: 'flex',
    justifyContent: ['center', 'initial'],
    flexFlow: 'column nowrap',
    position: 'relative',
    width: '100%',
    maxHeight: 'var(--dialog-max-height, 100%)',
  },
  section: {
    px: 4,
    py: 2,
    fontWeight: 'body',
    color: 'rgba(69, 67, 76, 0.8)',
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    textAlign: 'left',
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: '50px',
    zIndex: 1,
    '&:last-child': {
      pb: 2,
    },
  },
  footer: {
    background: 'var(--dialog-bg, white)',
    px: 4,
    py: ['min(var(--s4), 2vh)', 4],
    fontSize: 2,
    display: 'flex',
    gap: 2,
    borderRadius: '0 0 5px 5px',
    justifyContent: 'end',
  },
  loginToContinue: {
    variant: 'cards.info',
    position: 'relative',
    mt: [0, 'auto'],
    mb: [0, 'auto'],
    mx: [0, 'auto'],
    pt: 5,
    backgroundColor: 'background',
    width: '100%',
    height: ['100%', 'auto'],
    maxWidth: ['100%', '500px'],
    maxHeight: ['100%', '85vh'],
    textAlign: 'center',
    h2: {
      color: 'grayHeading',
    },
  },
  chooseAlbum: {
    borderRadius: '4px',
    color: 'activeGrayText',
    minWidth: '500px',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflowY: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',

    '@media screen and (max-width: 500px)': {
      minWidth: '100vw',
    },
  },
  album: {
    variant: 'dialogs.chooseAlbum',
    width: '80%',
  },
  shareDialogWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    gap: 3,
    pb: 3,
    '> :nth-child(2n)': {
      justifySelf: 'end',
    },
  },
})
