import { makeVariant } from '../../../types/makeVariant'

export const grids = makeVariant({
  primary: {
    fontSize: 1,
  },
  row: {
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
  },
  rowDivider: {
    gridColumn: '1 / -1',
    backgroundColor: 'border',
    height: '1px',
  },
  sessionlist: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr max-content max-content max-content',
    columnGap: 3,
    fontSize: 2,
    h2: {
      p: 4,
      m: 0,
      gridColumn: '1 / -1',
      borderTop: '1px solid',
      borderLeft: '1px solid',
      borderRight: '1px solid',
      borderColor: 'border',
    },
    '.col1, .col2': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '.deletebutton': {
      fontSize: '24px',
    },
    '.morebutton': {
      p: 4,
      m: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gridColumn: '1 / -1',
      borderBottom: '1px solid',
      borderLeft: '1px solid',
      borderRight: '1px solid',
      borderColor: 'border',
    },
    '.spacer': {
      height: 4,
      gridColumn: '1 / -1',
      borderTop: '1px solid',
      borderColor: 'border',
    },
    h3: {
      m: 0,
    },
    '.first': {
      pl: 4,
      borderLeft: '1px solid',
      borderLeftColor: 'border',
    },
    '.last': {
      pr: 2,
      borderRight: '1px solid',
      borderRightColor: 'border',
    },
  },
  pathItemRow: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    fontSize: 2,
    borderBottom: '1px solid',
    borderBottomColor: 'border',
    backgroundColor: 'background',
    '& > *': {
      flex: '0 0 auto',
      px: 2,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '[data-checkbox]': {
      p: 2,
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
    },
    '[data-icon]': {
      px: 0,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'content-box',
      width: '32px',
      overflow: 'visible',
      img: {
        width: '100%',
      },
      svg: {
        variant: 'styles.fileIcon',
        width: '32px',
        height: '32px',
      },
      '[data-shareicon]': {
        color: 'var(--color-share-icon)',
        size: 3,
        position: 'absolute',
        bottom: 0,
        right: 0,
        transform: 'translate(25%, 25%)',
      },
    },
    '[data-link]': {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      flex: '1 1 auto',
      overflow: 'hidden',
      '& > span': {
        flex: '1 1 auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '[data-date]': {
      display: ['none', 'block'],
      width: '8em',
      textAlign: 'right',
    },
    '[data-size]': {
      textAlign: 'right',
      width: '6em',
    },
    '[data-type]': {
      width: '4em',
    },
    '[data-actions]': {
      boxSizing: 'content-box',
      width: '24px',
    },
  },
  pathItemThumb: {
    '--hover-opacity': 0,
    display: 'grid',
    gridTemplateRows: '1fr 1.8em',
    padding: 2,
    position: 'relative',
    zIndex: 2,
    width: '100%',
    height: '100%',
    backgroundColor: 'background',
    overflow: 'hidden',
    contain: 'content',

    '&:hover': {
      '--hover-opacity': 1,
    },
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'lightGray',
      opacity: 'var(--hover-opacity)',
      transition: 'opacity 600ms',
      zIndex: '-1',
    },
    '[data-checkbox]': {
      width: '2em',
      position: 'absolute',
      top: 3,
      left: 3,
      zIndex: 2,
      transition: 'opacity 600ms',
      opacity: 'var(--hover-opacity)',
      userSelect: 'none',
      '&[data-checked="true"]': {
        opacity: 1,
      },
    },
    '[data-icon]': {
      backgroundColor: 'muted',
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      borderRadius: 2,
      width: '100%',
      height: '100%',
      img: {
        width: '100%',
        height: '100%',
      },
      svg: {
        variant: 'styles.fileIcon',
        alignSelf: 'center',
        justifySelf: 'center',
        width: '64px',
        height: '64px',
      },
    },
    '[data-link]': {
      textAlign: 'center',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 'var(--font-size)',
      paddingTop: 'var(--s2)',
    },
    '[data-actions]': {
      position: 'absolute',
      display: 'flex',
      top: 3,
      right: 3,
      width: 4,
      height: 4,
      fontSize: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'background',
      borderRadius: 1,
      zIndex: 2,
      opacity: 0,
      transition: 'opacity 300ms',
    },
  },
})
