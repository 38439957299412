import { makeVariant } from '../../../types/makeVariant'

export const buttons = makeVariant({
  primary: {
    '--focus-color': 'var(--color-accent)',
    whiteSpace: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary',
    borderColor: 'border',
    borderWidth: '1px',
    '@media (-webkit-min-device-pixel-ratio: 2), @media (min-resolution: 2dppx)':
      {
        borderWidth: '0.5px',
      },
    borderStyle: 'solid',
    borderRadius: 1,
    color: 'background',
    cursor: 'pointer',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: 2,
    fontWeight: 'normal',
    fontFamily: 'body',
    position: 'relative',
    px: 4,
    py: 2,
    transition: 'opacity 300ms, filter 300ms',
    '&:not(:disabled):hover': {
      filter: 'brightness(0.9)',
      opacity: 0.9,
    },
    '&:focus-visible': {
      boxShadow: '0 0 0 2px white, 0 0 0 4px var(--focus-color)',
    },
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
    '&[aria-busy="true"]': {
      '&::after': {
        justifySelf: 'flex-start',
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationName: 'spin',
        animationTimingFunction: 'linear',
        borderColor: 'currentColor',
        borderRadius: '50%',
        borderRightColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 2,
        color: 'currentColor',
        content: '" "',
        height: 4,
        left: '50%',
        marginLeft: t => `calc(-${t.space && t.space[4]}px / 2)`,
        opacity: 0.7,
        position: 'absolute',
        width: 4,
      },
      opacity: 0.5,
    },
    '> svg': {
      flex: '0 0 auto',
      width: 'max(24px, 1em)',
      height: 'max(24px, 1em)',
      '&:not(:last-child)': {
        mr: 2,
      },
    },
    '&[data-reverse="true"]': {
      flexFlow: 'row-reverse nowrap',
      '& > svg:not(:last-child)': {
        mr: 0,
        ml: 2,
      },
    },
  },
  secondary: {
    variant: 'buttons.primary',
    borderColor: 'border',
    color: 'text',
    backgroundColor: 'secondary',
    '--focus-color': t => String(t.colors?.border),
  },
  accent: {
    variant: 'buttons.primary',
    backgroundColor: 'primary',
    borderColor: 'primary',
    color: 'primaryText',
    '--focus-color': t => String(t.colors?.primary),
  },
  error: {
    variant: 'buttons.primary',
    borderColor: 'danger',
    backgroundColor: 'danger',
    color: 'dangerText',
    '--focus-color': t => String(t.colors?.danger),
  },
  plain: {
    variant: 'buttons.primary',
    fontSize: '1em',
    color: 'currentColor',
    backgroundColor: 'transparent',
    border: 0,
    '--focus-color': t => String(t.colors?.text),
    ':focus-visible': {
      boxShadow: 'none',
    },
  },
  action: {
    variant: 'buttons.plain',
    px: 0,
    '& > svg + span': {
      display: ['none', 'block'],
    },
  },
  commentsButton: {
    display: 'flex',
    padding: '0px 25px 0px 25px',
    fontSize: 3,
    gap: 2,
    whiteSpace: 'nowrap',
    height: '60px',
    borderRadius: '30px',
    transition: 'transform 0.1s ease 0s, color 0.1s ease 0s',
    boxShadow: 'rgb(0 0 0 / 30%) 0px 3px 12px 0px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.5)',
    svg: {
      fontSize: 5,
    },
  },
  bellIcon: {
    color: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25em',
    transition: 'opacity 0.3s ease',
    height: '100%',
    opacity: 1,
    px: 2,
    borderRadius: 2,
    '> svg': {
      fontSize: '1.25em',
    },
    '&:focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--color-focus)',
    },
  },
  replyButton: {
    color: ' var(--color-primary)',
    opacity: 0.8,
    fontSize: 'var(--fz0)',
    padding: '12px',
  },
  replyButtonDarkMode: {
    variant: 'buttons.replyButton',
  },
  text: {
    fontSize: '1em',
    display: 'inline-flex',
    verticalAlign: 'top',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    color: 'currentColor',
    bg: 'transparent',
    border: 0,
    px: 0,
    py: 0,
    ml: 0,
    '> svg': {
      variant: 'styles.buttonIcon',
      '&:not(:last-child)': {
        mr: 2,
      },
    },
    '&[data-reverse="true"]': {
      flexFlow: 'row-reverse nowrap',
      '& > svg:not(:last-child)': {
        mr: 0,
        ml: 2,
      },
    },
    ':focus': {
      boxShadow: 'none',
      color: 'primary',
    },
  },
  closeIconButton: {
    '--size': '24px',
    borderRadius: 2,
    px: 2,
    py: 2,
    display: 'flex',
    alignItems: 'center',
    fontSize: 'var(--size)',
    width: 'var(--size)',
    height: 'var(--size)',
    boxSizing: 'content-box',
    '&:focus-visible': {
      boxShadow: '0 0 0 2px var(--color-focus)',
      color: 'inherit',
    },
  },
  dropdownLink: {
    variant: 'buttons.plain',
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'stretch',
    ':hover': {
      opacity: 0.5,
    },
  },
  textIcon: {
    variant: 'buttons.text',
    fontSize: '16px',
    color: '#4D4D4D',
    ':hover': {
      color: '#000',
    },
  },
  iconButton: {
    variant: 'buttons.text',
    mr: '0.3em',
    pr: '4px',
    color: '#8F98A1',
    ':hover': {
      color: '#333',
    },
  },
  link: {
    variant: 'buttons.text',
    fontSize: '1em',
    color: 'primary',
  },
  topToolBar: {
    variant: 'links.topToolBar',
  },
  hamburger: {
    alignSelf: 'center',
    variant: 'buttons.text',
    boxSizing: 'content-box',
    pl: '20px',
    pr: '16px',
    width: '18px',
    height: '12px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    '&::before, &::after, > span': {
      content: '" "',
      borderRadius: '1px',
      height: '2px',
      width: '100%',
      background: '#7f7f7f',
    },
  },
  albumActionButton: {
    '--_padding': 'var(--action-button-padding, var(--s2) var(--s3))',
    '--_icon-opacity': 'var(--action-button-icon-opacity, 0.7)',
    '--_icon-hover-opacity': 'var(--action-button-icon-hover-opacity, 1)',
    '--_text-opacity': 'var(--action-button-text-opacity, 0.7)',
    '--_text-hover-opacity': 'var(--action-button-text-hover-opacity, 1)',
    '--_icon-size': 'var(--action-button-size, 24px)',
    '--_color': 'var(--action-button-color, currentColor)',
    '--_focus-color': 'var(--action-button-focus-color, var(--color-focus))',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: 2,
    fontSize: 2,
    color: 'var(--_color)',
    borderRadius: 1,
    padding: 'var(--_padding)',
    whiteSpace: 'nowrap',
    '&:disabled': {
      opacity: 0.3,
    },
    '&:focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--_focus-color)',
    },
    '& > span': {
      flex: '1 1 auto',
      opacity: 'var(--_text-opacity)',
    },
    '&:hover > span': {
      opacity: 'var(--_text-hover-opacity)',
    },
    '& > svg': {
      flex: '0 0 auto',
      opacity: 'var(--_icon-opacity)',
      width: 'var(--_icon-size)',
      height: 'var(--_icon-size)',
    },
    '&:hover > svg': {
      opacity: 'var(--_icon-hover-opacity)',
    },
  },
  responsiveActionButton: {
    variant: 'buttons.albumActionButton',
    '& > span': {
      display: ['none', 'initial'],
      flex: '1 1 auto',
      opacity: 'var(--_text-opacity)',
    },
  },
  carouselActionButton: {
    variant: 'buttons.albumActionButton',
    '@media screen and (max-width: 1099px)': {
      '.sidepanel-visible & > span': {
        display: 'none',
      },
    },
    '@media screen and (max-width: 749px)': {
      '& > span': {
        display: 'none',
      },
    },
  },
  fileViewerActionButton: {
    variant: 'buttons.text',
    px: 1,
    '&:disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  removeLinkActionButton: {
    variant: 'buttons.text',
    color: 'red',
    '--focus-color': t => String(t.colors?.red),
    opacity: 0.7,
    '>svg': { mr: 0 },
    ':hover': { opacity: 0.9 },
    ':focus': { opacity: 0.9 },
    '>div': {
      display: ['none', 'block'],
    },
  },
  vipps: {
    px: 0,
    py: 0,
    color: 'white',
    width: ['100%', 'auto'],
    height: ['auto', '2.5em'],
  },
  uploadActionButton: {
    variant: 'buttons.text',
    color: 'purpleHueGray',
    '>svg': { mr: 1 },
    ':hover': { opacity: 0.8 },
    ':focus': { opacity: 0.8 },
    '>span': {
      display: 'block',
    },
  },
  download: {
    variant: 'buttons.primary',
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '4px',
    color: 'text',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    padding: '12px 13px 10px',
    fontSize: 3,
    letterSpacing: '0.5px',
    lineHeight: 1.4,
    fontWeight: 500,
    minWidth: '156px',
    width: 'auto',
    whiteSpace: 'normal',
    '&:not(:disabled):hover': {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      boxShadow: '0 0.5em 0.5em -0.4em rgba(0, 0, 0, 0.15)',
      transform: 'translateY(-0.25em)',
      opacity: 1,
    },
    ':focus': {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      boxShadow: '0 0.5em 0.5em -0.4em rgba(0, 0, 0, 0.15)',
      transform: 'translateY(-0.25em)',
      opacity: 1,
    },
  },
  languageSelector: {
    variant: 'buttons.secondary',
    p: '8px',
    minWidth: 'none',
    width: 'none',
    borderRadius: 'none',
    border: '1px solid #ddd',
    backgroundColor: 'white',
  },
  openWebInterface: {
    variant: 'buttons.secondary',
    fontSize: '16px',
    fontWeight: 'light',
    color: '#232323',
    border: '1px solid #ebeaec',
    borderRadius: '4px',
    mr: ['10px', '16px', '16px'],
    position: ['absolute', 'inherit', 'inherit'],
    left: ['24px', 0, 0],
    mt: ['160px', 0, 0],
    '&:not(:disabled):hover': {
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  copyInputButton: {
    variant: 'buttons.primary',
    bg: 'primary',
    borderColor: 'border',
    '--focus-color': 'var(--color-primary)',
  },
  danger: {
    color: 'danger',
  },
  smartSearchPrimaryButton: {
    variant: 'buttons.primary',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    bg: 'primary',
    borderColor: 'primary',
    borderRadius: '8px',
    color: 'white',
    '--focus-color': 'var(--color-primary)',
    '&:not(:disabled):hover': {
      filter: 'brightness(0.9)',
    },
  },
  smartSearchLightButton: {
    variant: 'buttons.smartSearchPrimaryButton',
    gap: 1,
    bg: 'white',
    color: 'primary',
    whiteSpace: 'normal',
    textAlign: 'left',
    '> svg': {
      '&:not(:last-child)': {
        mr: 0,
      },
    },
  },
  smartSearchTextButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    color: 'primary',
    '&:not(:disabled):hover': {
      filter: 'brightness(0.9)',
    },
  },
})
