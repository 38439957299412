import type { ThemeUIStyleObject } from '@jotta/ui/themeui'
import { alerts } from './alerts'
import { badges } from './badges'
import { buttons } from './buttons'
import { cards } from './cards'
import { dialogs } from './dialogs'
import { formats } from './formats'
import { forms } from './forms'
import { grids } from './grids'
import { images } from './images'
import { layout } from './layout'
import { links } from './links'
import { messages } from './messages'
import { styles } from './styles'
import { tables } from './tables'
import { text } from './text'

export const variants = {
  alerts,
  badges,
  buttons,
  cards,
  dialogs,
  formats,
  forms,
  grids,
  images,
  layout,
  links,
  messages,
  styles,
  tables,
  text,
} as const satisfies Record<string, Record<string, ThemeUIStyleObject>>
