import { makeVariant } from '../../../types/makeVariant'

export const images = makeVariant({
  primary: {
    fontSize: 1,
  },
  familyPicture: {
    display: ['none', 'none', 'block'],
    gridColumn: [1, 'button1 / span 2'],
    width: '392px',
    height: '252px',
    justifySelf: 'end',
    color: 'primary',
  },
  poweredByJotta: {
    position: 'absolute',
    bottom: '16px',
    width: '156px',
    height: '19px',
  },
})
